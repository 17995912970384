import React from 'react'
import CreateNewBatch from './CreateNewBatch'

const BatchDetails = () => {
  return (
    <>
      <CreateNewBatch/>
    </>
  )
}

export default BatchDetails